import env from '../env';

function getStrategyVariant (strategy) {
  switch (strategy) {
    case 'lifeCycle':
      return 'lifecycle';

    case 'pension':
      return 'retirement';

    case 'customFundCombination':
      return 'custom';

    default:
      return strategy;
  }
}

function getFormStep (path) {
  switch (true) {
    case path.includes('kontaktni-udaje'):
    case path.includes('volba-zadani-osobnich-udaju'):
    case path.includes('upload-dokladu'):
    case path.includes('scan-dokladu-prechod-na-mobil'):
    case path.includes('zadani-osobnich-udaju'):
      return '1';

    case path.includes('zakonne-pozadavky'):
      return '2';

    case path.includes('vyse-prispevku-a-strategie'):
      return '3';

    case path.includes('smlouva-nastaveni-parametru'):
    case path.includes('smlouva-dokumenty-a-souhlasy'):
    case path.includes('smlouva-sms-podpis'):
      return '4';

    case path.includes('dekujeme'):
      return 'success';

    default:
      return null;
  }
}

function measure (action, formId, contractId, formStep, payload) {
  if (!env.AA_ENABLE) {
    console.warn('Adobe analytics is disabled, skipping.');

    return;
  }

  if (!window.measure) {
    throw new Error('Global variable `measure` does not exist, skipping.');
  }

  const data = {
    action,
    formid: formId,
    formstep: formStep,
    applicationnumber: contractId,
    domain: window.location.host,
    ...payload,
  };

  if (env.AA_DEBUG) {
    console.warn(data);
  }

  window.measure(data);
}

export default {
  measure,
  getFormStep,
  getStrategyVariant,
};
