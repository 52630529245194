<template>
  <div class="body">
    <main>
      <div class="container">
        <MonthlyContributionIncreaseForm
          v-if="isVisible"
          v-model="newContribution"
          :current-contribution="contributionData.old_month_payment"
          :recommended-contribution="contributionData.recommended_month_payment"
          :date-of-change="formateDate(contributionData.effective_date)"
          :client-email="contributionData.contract_email"
          :contract-number="contributionData.contract_number"
          @submit="submit"
        >
          <template #afterQuestionnaire>
            <LitAlert
              v-if="validationErrors.message"
              alert-type="danger"
              class="wrapper-max-width"
            >
              {{ validationErrors.message }}
            </LitAlert>
          </template>
        </MonthlyContributionIncreaseForm>
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup>
import { MonthlyContributionIncreaseForm } from '@csob-penze/csob-penze-design';
import { onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import aa from '@/js/services/adobeAnalytics';
import env from '@/js/env';

import LitAlert from '@/js/components/Base/LitAlert.vue';

interface ContributionData {
  id: number | null
  contract_number: string
  contract_email: string
  old_month_payment: null
  recommended_month_payment: number | null
  effective_date: string
}

const newContribution = ref<number>(0);
const isVisible = ref(false);
const contributionData = reactive<ContributionData>({
  id: null,
  contract_number: '',
  contract_email: '',
  old_month_payment: null,
  recommended_month_payment: null,
  effective_date: '',
});
const errorMsg = ref('Něco se nepovedlo');
const errorPageMetadata = {
  name: 'error',
  query: {
    headersVisible: 'false',
    title: 'Omlouváme se, ale odkaz je neplatný',
    body: 'Buď už jste si měsíční příspěvek navýšili nebo odkaz expiroval. Pro změnu měsíčního příspěvku využijte Penzijní portál',
    contactVisible: 'false',
  },
};
const validationErrors = ref({
  message: '',
  errors: [],
});

const route = useRoute();
const router = useRouter();

function formateDate (date: string) {
  const newDate = new Date(date);
  return `${newDate.getDate()}. ${newDate.getMonth() + 1}. ${newDate.getFullYear()}`;
}

async function updateContribution (id: number, opts: { contribution: number, questionnaire: any }) {
  const response = await makeApiCall(
    `${env.REST_API_URI}/v1/contributions/${id}`,
    {
      new_month_payment: opts.contribution,
      questionnaire: opts.questionnaire,
    },
    'PATCH',
  );

  if (response?.status === 422) {
    return {
      success: false,
      errors: response.errors,
      status: 422,
    };
  }

  try {
    aa.measure('ufSubmit', 'penze-navyseni', response.contract_number, '1', {
      contractid: response.contract_number,
      marketingagreement: 'true',
      domain: 'csob-penze.cz',
    });
  } catch (e) {
    console.error(e);
  }

  // Handle null response or invalid new_month_payment
  if (!response || response.new_month_payment === null) {
    await router.push(errorPageMetadata);
    return;
  }

  await router.push({
    name: 'ContributionThankYou',
    query: {
      newPayment: response.new_month_payment,
      contractId: response.contract_number,
      applicationNumber: response.contract_number,
    },
  });
}

async function createContribution (params: string | null) {
  if (params === null) {
    return;
  }

  const data = await makeApiCall(
    `${env.REST_API_URI}/v1/contributions`,
    { hash: params },
  );

  try {
    aa.measure('ufFieldChanged', 'penze-navyseni', data.contract_number, '1', {
      contractid: data.contract_number,
      marketingagreement: 'true',
      domain: 'csob-penze.cz',
    });
  } catch (e) {
    console.error(e);
  }

  return data;
}

async function makeApiCall (uri: string, body: unknown, method = 'POST') {
  try {
    const response = await fetch(uri, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const status = response.status;

    if (status === 200 || status === 201) {
      return response.json();
    }

    if (status === 422) {
      const errorData = await response.json();
      throw new Error('UNPROCESSABLE_ENTITY', { cause: errorData });
    }

    throw new Error(errorMsg.value);
  } catch (e) {
    console.warn(e);

    if (e instanceof Error && e.message === 'UNPROCESSABLE_ENTITY') {
      const validationErrors = e.cause;

      console.error('Validation errors:', validationErrors);

      return {
        success: false,
        status: 422,
        errors: validationErrors,
      };
    }

    await router.push(errorPageMetadata);
    return null;
  }
}

async function submit (opts: { contribution: number, questionnaire: any }) {
  const contributionId = contributionData.id;
  validationErrors.value = {
    message: '',
    errors: [],
  };

  if (opts.contribution === null || contributionId === null) {
    return;
  }

  const response = await updateContribution(contributionId, opts);
  if (response?.status === 422) {
    validationErrors.value = response.errors;
  }
}

function isNullIncluded (data: Array<unknown>) {
  return data.includes(null);
}

function setContributionFields (params: ContributionData) {
  contributionData.id = params.id as number;
  contributionData.contract_number = params.contract_number;
  contributionData.contract_email = params.contract_email;
  contributionData.old_month_payment = params.old_month_payment;
  contributionData.recommended_month_payment = params.recommended_month_payment;
  contributionData.effective_date = params.effective_date;

  // set recommended contribution as default value
  if (params.recommended_month_payment) {
    newContribution.value = Number.parseInt(params.recommended_month_payment.toString(), 10);
  }
}

onMounted(async () => {
  const hashedQuery = route.query.hash;

  if (hashedQuery === null) {
    router.push(errorPageMetadata);
  }

  const createdContribution = await createContribution(hashedQuery as string);
  if (createdContribution === null) {
    return;
  }

  const fieldsAreValid = !isNullIncluded([
    createdContribution.id,
    createdContribution.contract_number,
    createdContribution.contract_email,
  ]);

  if (!fieldsAreValid) {
    router.push(errorPageMetadata);
  }

  setContributionFields(createdContribution);
  isVisible.value = true;
});
</script>

<style>
.wrapper-max-width {
  max-width: 472px;
}

.mb-8 {
  margin-bottom: 8px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
