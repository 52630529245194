<template>
  <div class="body">
    <div class="container--sm my-30">
      <LitAlert alert-type="success">
        <h2>
          Děkujeme vám za navýšení měsíčního příspěvku
        </h2>
        <p>Jamile bude váš požadavek zpracován, pošleme potvrzení na vaši e-mailovou adresu.</p>
      </LitAlert>
    </div>
  </div>
</template>

<script>
import LitAlert from '@/js/components/Base/LitAlert.vue';
import aa from '@/js/services/adobeAnalytics.js';

export default {
  components: {
    LitAlert,
  },

  data () {
    return {
      monthlyPayment: null,
    };
  },

  mounted () {
    this.monthlyPayment = this.$route.query.newPayment;
    this.contractId = this.$route.query.contractId;
    this.applicationNumber = this.$route.query.applicationNumber;

    if (!this.applicationNumber || !this.contractId) {
      return;
    }

    try {
      aa.measure('ufFormSuccess', 'penze-navyseni', this.applicationNumber, 'success', {
        contractid: this.contractId,
        marketingagreement: 'true',
        domain: 'csob-penze.cz',
      });
    } catch (e) {
      console.error(e);
    }
  },

};
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';

h2 {
  margin-bottom: 0;
}
</style>
